import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, filter, map, Observable, Subject, takeUntil, tap} from "rxjs";

import {Apollo, gql, MutationResult} from "apollo-angular";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";
import {CountryDTO} from "./dto";
import {SHIP_OWNER_FIELD} from "./ship-owner.service";

export const COUNTRY_FIELD = gql`
  fragment COUNTRY_FIELD on CountryDTO {
    id
    name
  }
`;


const add = gql`
  ${COUNTRY_FIELD}

  mutation AddCountry($dto: CountryInput!) {
    country_add_one(body: $dto) {
      ...COUNTRY_FIELD
    }
  }`
const update = gql`
  ${COUNTRY_FIELD}

  mutation UpdateCountry($id: Int!, $dto: CountryInput!) {
    country_update_one(pk_columns: {id: $id}, body: $dto){
      ...COUNTRY_FIELD
    }

  }`


const getAll = gql`
  ${COUNTRY_FIELD}

  query GetAllCountry($pageNumber: Int, $pageDim: Int, $where:String ) {
    country(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "name,desc") {
      ...COUNTRY_FIELD
    }
  }
`;

const getById = gql`
  ${COUNTRY_FIELD}
  ${SHIP_OWNER_FIELD}

  query GetCountryById($id: ID!) {
    country_by_pk(id: $id) {
      ...COUNTRY_FIELD

    }
  }`;


const count = gql`query CountCountry( $where: String ) {
  country_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;


@Injectable({
  providedIn: 'root'
})
export class CountryService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) {

  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation): Observable<{
    data: CountryDTO[],
    totalRows: number
  }> {

    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      tap(x => console.log(x)),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['country_aggregate']
        // @ts-ignore
        const data = x[0].data?.['country']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes: { includeParent: boolean } = {includeParent: false}): Observable<CountryDTO> {
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
        includeParent: includes.includeParent
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      filter(x => !x.loading),
      map(x => {
          // @ts-ignore
          const data = x.data?.['country_by_pk'];
          const d = (({__typename, ...o}) => o)(data)
          return d
        }
      )
    );
  }

  //

  add(dto: CountryDTO): Observable<CountryDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.country_add_one))
  }

  update(id: number, dto: CountryDTO): Observable<CountryDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.country_update_one))
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
