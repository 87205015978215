import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import moment from 'moment';
import 'moment/locale/it';
import {FormBuilder, FormGroup} from "@angular/forms";
import {filter} from "rxjs";

@Component({
  selector: 'app-date-navigator',
  templateUrl: './date-navigator.component.html',
  styleUrls: ['./date-navigator.component.scss']
})
export class DateNavigatorComponent implements OnInit {
  @Output() dateChanged = new EventEmitter<moment.Moment>();
  form: FormGroup;
  private _currentDate = moment();

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      date: undefined
    });

    this.form.get('date').valueChanges.pipe(filter(x=> this._currentDate.toDate().getTime() != moment(x).toDate().getTime())).subscribe(x => {

      this.emitDateChange();
    })

  }

  initForm() {
    this.form.patchValue({
      date: this._currentDate.format('YYYY-MM-DD')
    });

  }

  ngOnInit(): void {
    moment.locale('it');  // Imposta la localizzazione italiana
    this.emitDateChange();
  }

  changeDate(days: number): void {
    this._currentDate.add(days, 'days');
    this.form.get('date').setValue(this._currentDate.format('YYYY-MM-DD'))
    this.emitDateChange();

  }


  private emitDateChange(): void {
    this.dateChanged.emit(moment(this.form.getRawValue().date).startOf("day"));
  }



  @Input()
  set currentDate(value: moment.Moment) {
    this._currentDate = value;
    this.initForm()
  }
}
