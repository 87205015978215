import {defer, finalize, Observable, Subject} from "rxjs";
import {CurrencyIso3Type} from "../service/dto";

export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source;
  });
}

export function indicate<T>(indicator: Subject<boolean>): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => indicator.next(true)),
    finalize(() => indicator.next(false))
  )
}

export function indicateWithParams<T>(indicator: Subject<{
  status: boolean,
  id: any
}>, id?: any): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => indicator.next({status: true, id: id})),
    finalize(() => indicator.next({status: true, id: id}))
  )
}

export function isUUID(uuid: string) {
  var regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(uuid);
}


export function stringToColor(string, saturation = 100, lightness = 75) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupBy(list: any[], keyGetter: Function) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const months = [
  'Jen',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Set',
  'Oct',
  'Nov',
  'Dic',
]
export type CurrencyType = { name: string, iso3: CurrencyIso3Type, symbol: string };
export const EURO: CurrencyType = {name: 'Euro', iso3: CurrencyIso3Type.EUR, symbol: "€"};
export const currency: CurrencyType[] = [
  {name: 'Euro', iso3: CurrencyIso3Type.EUR, symbol: "€"},  //euro
  {name: 'Dollar', iso3: CurrencyIso3Type.USD, symbol: "$"}, //dollaro americano
  {name: 'Pound Sterling', iso3: CurrencyIso3Type.GBP, symbol: "£"},//sterlina britannica
  {name: 'Yen', iso3: CurrencyIso3Type.CNY, symbol: "¥"},    //yen cinese
  {name: 'Rupee', iso3: CurrencyIso3Type.INR, symbol: "₹"},  //Rupee indiana
]


export  function formatBytes(bytes: number, decimals: number = 0) {
  if (!bytes) {
    return 'N/A';
  }
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
