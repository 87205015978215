import {Apollo, gql, MutationResult} from "apollo-angular";
import {SkipperDTO} from "./dto";
import {Injectable, OnDestroy} from "@angular/core";
import {combineLatest, filter, map, Observable, Subject, takeUntil} from "rxjs";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";

export const SKIPPER_FIELD = gql`
  fragment SKIPPER_FIELD on SkipperDTO {
    createdAt
    email
    firstname
    id
    lastname
    mobilePhone
    updatedAt


  }
`;

//
const add = gql`
  ${SKIPPER_FIELD}

  mutation AddSkipper($dto: SkipperInput!) {
    skipper_add_one(body: $dto) {
      ...SKIPPER_FIELD
    }
  }`
const update = gql`
  ${SKIPPER_FIELD}

  mutation UpdateShipOwner($id: ID!, $dto: SkipperInput!) {
    skipper_update_one(id: $id, body: $dto){
      ...SKIPPER_FIELD

    }

  }`


const getAll = gql`
  ${SKIPPER_FIELD}

  query GetAllSkippers($pageNumber: Int, $pageDim: Int, $where:String ) {
    skipper(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...SKIPPER_FIELD
    }
  }
`;

const getById = gql`
  ${SKIPPER_FIELD}

  query GetShipOwnerById($id: ID!) {
    skipper_by_pk(id: $id) {
      ...SKIPPER_FIELD

    }
  }`;


const count = gql`query CountSkipper( $where: String ) {
  skipper_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;


@Injectable({
  providedIn: 'root'
})
export class SkipperService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) {

  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation): Observable<{
    data: SkipperDTO[],
    totalRows: number
  }> {

    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));

    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));

    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['skipper_aggregate']
        // @ts-ignore
        const data = x[0].data?.['skipper']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes: { includeParent: boolean } = { includeParent: false }): Observable<SkipperDTO> {
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      map(x => {
        // @ts-ignore
        const data = x.data?.['skipper_by_pk'];
        console.log('Data fetched by getById:', data);  // Log per debug
        const d = (({__typename, ...o}) => o)(data)
        return d
      })
    );
  }


  //

  add(dto: SkipperDTO): Observable<SkipperDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.skipper_add_one))
  }

  update(id: number, dto: SkipperDTO): Observable<SkipperDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.skipper_update_one))
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
