import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";

interface NgLetContext {
  $implicit: any;
  ngLet: any;
}

@Directive({
  selector: "[ngLet]"
})
export class NgLetDirective {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<NgLetContext>
  ) {
  }

  @Input()
  set ngLet(value: any) {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.templateRef, {
      $implicit: value,
      ngLet: value
    });
  }
}
