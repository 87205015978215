<div class="relative">
  <div class="absolute top-2 right-2 flex flex-column space-y-1 z-50">
    <div
      class="cursor-pointer w-8 h-8 p-1 rounded-full border border-solid border-gray-400 flex justify-center items-center bg-gray-100"
      (click)="scaleUp()">
      <fa-icon [icon]="faPlus" class=" "></fa-icon>
    </div>
    <div
      class="cursor-pointer w-8 h-8 p-1 rounded-full border border-solid border-gray-400 flex justify-center items-center bg-gray-100"
      (click)="scaleDown()">
      <fa-icon [icon]="faMinus" class="  cursor-pointer" ></fa-icon>
    </div>
  </div>
  <div #mapContainer class="svgContainer" [innerHTML]="svg"></div>
</div>
