import {KeycloakOnLoad} from "keycloak-js";

const ORIGIN = window.location.host
const PROTOCOL = window.location.protocol
const REALM = 'triton'
const CLIENT_ID = "application-"+ORIGIN.split(".")[0].toLowerCase()
const BE_HOST = `${PROTOCOL}//${ORIGIN}/api`

export const environment = {
  public_endpoint: `${PROTOCOL}//${ORIGIN}/api`,
  graphql_endpoint: `${PROTOCOL}//${ORIGIN}/api/graphql`,
  ws_endpoint: `wss://${ORIGIN}/api/subscriptions`,
  keycloak: {
    url: `https://auth.triton.seaportsolution.com`,
    realm: `${REALM}`,
    clientId: `${CLIENT_ID}`,
    checkLoginIframe: true,
    onLoad: 'check-sso' as KeycloakOnLoad

  },

};
