import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {debounceTime, distinctUntilChanged, ReplaySubject} from "rxjs";
import {BerthDTO, ReservationDTO, ReservationRequestDTO} from "../../service/dto";
import {faMinus, faPlus, faSave, faSearchMinus, faSearchPlus} from "@fortawesome/free-solid-svg-icons";

export type ACTION = 'SELECTED' | 'DESELECTED'

@Component({
  selector: 'app-port-map',
  templateUrl: './port-map.component.html',
  styleUrl: './port-map.component.scss'
})
export class PortMapComponent implements OnInit, AfterViewInit {
  @Input()
  svg: any
  @Input()
  berths: BerthDTO[]
  private _reservations: ReservationDTO[]
  private _requests: ReservationRequestDTO[]
  content: ElementRef;

  @Output()
  onLoad = new EventEmitter<boolean>();
  @Output()
  onBerthSelect = new EventEmitter<[BerthDTO, ACTION, PointerEvent]>();
  clickBerth$ = new ReplaySubject();

  constructor(private renderer: Renderer2,) {
    this.clickBerth$.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe((event: any) => {
      const el = event.target;
      const svg = event.target.parentElement.parentElement;
      const isActive = el.classList.contains('active');
      svg.querySelectorAll('.active').forEach((x: any) => x.classList.remove("active"));
      const id = el.getAttribute('id');
      const berth: BerthDTO | undefined = this.berths.find(x => x.code === id);

      if (!isActive) {
        el.classList.add('active');
        if (berth)
          this.onBerthSelect.emit([berth, 'SELECTED', event])
      } else {
        this.onBerthSelect.emit([berth, 'DESELECTED', event])
      }
    });
  }

  ngOnInit(): void {
  }

  @ViewChild('mapContainer')
  set mapContainer(content: ElementRef) {

    if (content && !this.content) {
      this.content = content;
      this.applyListener(content)
      // this.reservations$.next(null);
      this.onLoad.emit(true)
    }
  }

  private clearBusyBerths(): void {
    const busyBerths = this.content.nativeElement.querySelectorAll('svg > g > path.busy');
    busyBerths.forEach((berth: any) => {
      berth.classList.remove('busy');
    });
  }

  private applyListener(content: ElementRef): void {
    if (!content)
      return
    const pathList = content.nativeElement.querySelectorAll('svg > g > path');

    for (let p of pathList) {
      this.renderer.listen(p, 'click', this.onClickCallback.bind(this));
      // this.renderer.setStyle(p, 'cursor', 'pointer');
      this.renderer.addClass(p, 'barth');
    }
  }


  get reservations(): ReservationDTO[] {
    return this._reservations;
  }

  @Input()
  set reservations(value: ReservationDTO[]) {
    this._reservations = value;
    this.applyListener(this.content)
    this.updateReservations();
  }

  @Input()
  set requests(value: ReservationRequestDTO[]) {
    this._requests = value;
    this.applyListener(this.content)
    this.updateReservations();
  }

  updateReservations() {

    if (!this.content) {
      return
    }
    this.clearBusyBerths();

    for (let res of this._reservations) {
      const berthCode = res.berth.code;
      const berth = this.content.nativeElement.querySelector(`svg > g > path[id='${berthCode}']`);
      if (berth) {
        this.renderer.addClass(berth, 'busy');
        // berth.classList.add('busy');
      }
    }

    for (let res of this._requests) {
      const berthCode = res.reservedBerth.code;
      const berth = this.content.nativeElement.querySelector(`svg > g > path[id='${berthCode}']`);
      if (berth) {
        this.renderer.addClass(berth, 'requested');
        // berth.classList.add('busy');
      }
    }
    // this.ngZone.run(() => {
    //   this.cdr.detectChanges();
    // });
  }

  onClickCallback(event: any): void {
    this.clickBerth$.next(event);
  }

  protected readonly faSave = faSave;
  protected readonly faSearchPlus = faSearchPlus;
  protected readonly faSearchMinus = faSearchMinus;

  scaleUp() {
    const svgEl = this.content.nativeElement.querySelector("svg")
    let scaleVal = svgEl.style.scale
    if(!scaleVal)
      scaleVal = 1
    svgEl.style.scale = Number(scaleVal) + 0.1;

  }

  scaleDown() {
    const svgEl = this.content.nativeElement.querySelector("svg")
    let scaleVal = svgEl.style.scale
    if(!scaleVal)
      scaleVal = 1
    if(Number(scaleVal) - 0.1 < 1)
      return
    svgEl.style.scale = Number(scaleVal) - 0.1;
  }

  protected readonly faPlus = faPlus;
  protected readonly faMinus = faMinus;

  ngAfterViewInit(): void {
    this.applyListener(this.content)
    this.updateReservations();
  }
}
