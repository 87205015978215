import {Component, Input} from '@angular/core';

export  type Size = 'M' | 'XL'

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
  @Input()
  size: Size = 'M';
}
