import {Apollo, gql, MutationResult} from "apollo-angular";
import {ShipOwnerDTO} from "./dto";
import {Injectable, OnDestroy} from "@angular/core";
import {combineLatest, filter, map, Observable, Subject, takeUntil} from "rxjs";
import {BoolOperation, ExpressionBuilder} from "./ExpressionBuilder";

export const SHIP_OWNER_FIELD = gql`
  fragment SHIP_OWNER_FIELD on ShipOwnerDTO {
    email
    firstname
    id
    lastname
    mobilePhone
    pec
    phone
    taxCode
    type
    vatCode


  }
`;

//
const add = gql`
  ${SHIP_OWNER_FIELD}

  mutation AddShipOwner($dto: ShipOwnerInput!) {
    shipOwner_add_one(body: $dto) {
      ...SHIP_OWNER_FIELD
    }
  }`
const update = gql`
  ${SHIP_OWNER_FIELD}

  mutation UpdateShipOwner($id: ID!, $dto: ShipOwnerInput!) {
    shipOwner_update_one(id: $id, body: $dto){
      ...SHIP_OWNER_FIELD

    }

  }`


const getAll = gql`
  ${SHIP_OWNER_FIELD}

  query GetAllShipOwner($pageNumber: Int, $pageDim: Int, $where:String ) {
    shipOwner(pageNum: $pageNumber, pageDim: $pageDim, where: $where, sort: "id,desc") {
      ...SHIP_OWNER_FIELD
    }
  }
`;

const getById = gql`
  ${SHIP_OWNER_FIELD}

  query GetShipOwnerById($id: ID!) {
    shipOwner_by_pk(id: $id) {
      ...SHIP_OWNER_FIELD

    }
  }`;


const count = gql`query CountShipOwner( $where: String ) {
  shipOwner_aggregate(aggregation: {count: {field: "id", distinct: false}}, where: $where) {
    count
  }
}`;


@Injectable({
  providedIn: 'root'
})
export class ShipOwnerService implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo) {

  }

  getAll(page: number = 0, pageSize: number = 10, filters?: BoolOperation): Observable<{
    data: ShipOwnerDTO[],
    totalRows: number
  }> {

    const fetchData = this.apollo
      .watchQuery({
        query: getAll,
        variables: {
          pageNumber: page,
          pageDim: pageSize,
          where: ExpressionBuilder.toGql(filters),
        },
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));

    const fetchCount = this.apollo
      .watchQuery({
        query: count,
        variables: {where: ExpressionBuilder.toGql(filters)},
        fetchPolicy: 'no-cache',

      }).valueChanges.pipe(filter(c => !c.loading));
      
    return combineLatest([fetchData, fetchCount]).pipe(
      takeUntil(this.destroy$),
      filter(x => !!x[0].data),
      map(x => {
        // @ts-ignore
        const aggRes = x[1].data['shipOwner_aggregate']
        // @ts-ignore
        const data = x[0].data?.['shipOwner']
        const d = data.map((y: any) => (({__typename, ...o}) => o)(y));
        return {
          data: d,
          totalRows: aggRes.count
        }
      })
    )
  }

  getById(id: number, includes: { includeParent: boolean } = { includeParent: false }): Observable<ShipOwnerDTO> {
    return this.apollo.watchQuery({
      query: getById,
      variables: {
        id: id,
      },
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      filter(x => !x.loading),
      takeUntil(this.destroy$),
      map(x => {
        // @ts-ignore
        const data = x.data?.['shipOwner_by_pk'];
        console.log('Data fetched by getById:', data);  // Log per debug
        const d = (({__typename, ...o}) => o)(data)
        return d
      })
    );
  }
  

  //

  add(dto: ShipOwnerDTO): Observable<ShipOwnerDTO> {
    return this.apollo.mutate({
      mutation: add,
      variables: {dto: dto},
      fetchPolicy: 'no-cache',
    }).pipe(map((x: MutationResult) => x.data.shipOwner_add_one))
  }

  update(id: number, dto: ShipOwnerDTO): Observable<ShipOwnerDTO> {
    return this.apollo.mutate({
      mutation: update,
      variables: {id: id, dto: dto},
      fetchPolicy: 'no-cache',

    }).pipe(map((x: MutationResult) => x.data.shipOwner_update_one))
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
