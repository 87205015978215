export enum BoatEngineDTO_PropulsionType {
  SAILING = 'SAILING',
  OARS = 'OARS',
  ENGINE = 'ENGINE'
}

export interface BoatEngineDTO {
  id: number;
  model: string;
  brand: string;
  serialNumber: string;
  kw: number;
  cv: number;
  boatId: number;
  propulsionType: BoatEngineDTO_PropulsionType;
  createdAt: Date;
  updatedAt: Date;
}

export interface BoatDTO {
  id: number
  beam: number
  createdAt: Date
  description: string
  draft: number
  length: number
  boatModel: string
  plateNumber: string
  flag: CountryDTO
  flagId: number
  name: string
  registryNumber: string
  shipOwnerId: number
  shipOwner?: ShipOwnerDTO
  type?: BoatTypeDTO
  typeId: number
  updatedAt: Date
  maxPassengers: number
  boatImages?: AttachmentDTO[]
  insuranceData?: InsuranceDataDTO[]
  engineId: number;
  engine?: BoatEngineDTO;
  skipperId: number
  skipper?: SkipperDTO
}

export interface CountryDTO {
  id: number
  name: string
}

export interface DockingTypeDTO {
  id: number
  name: string
}

export enum RequestStatus {
  TO_HANDLE = "TO_HANDLE",
  CANCELLED = "CANCELLED",
  DONE = "DONE",
  REFUSED = "REFUSED",
  ACCEPTED = "ACCEPTED"
}

export interface ReservationRequestDTO {
  id: number
  transit: boolean
  dockingId: number
  docking?: DockingTypeDTO
  arrivalDate: Date | string
  departureDate: Date | string
  nextHarbour: string
  lastHarbour: string
  cashDeposit: number
  length: number
  beam: number
  draft: number
  boatName: string
  typeId: number
  boatType?: BoatTypeDTO
  portId: number
  port?: PortDTO
  createdAt: Date
  reservedBerthId: number
  reservedBerth: BerthDTO
  status: RequestStatus
  source: string
  depositCurrency: CurrencyIso3Type
  email: string
  price: number
  currencyIso3: CurrencyIso3Type
  expirationDate: Date | string
  attachmentDirectory?: AttachmentDirectoryDTO
  userNote: string
}

export interface BoatTypeDTO {
  id: number
  name: string
}

enum OwnerType {
  PERSON = "PERSON",
  COMPANY = "COMPANY"
}

export interface ShipOwnerDTO {
  id: number;
  firstname: string;
  lastname: string;
  vatCode: string;
  taxCode: string;
  mobilePhone: string;
  phone: string;
  email: string;
  pec: string;
  type: OwnerType;
}

export interface BerthDTO {
  id: number
  code: string
  length: number
  beam: number
  draft: number
  portId: number
  port?: PortDTO
  createdAt: Date
}

export enum NotificatonStatus {
  TO_READ = "TO_READ",
  READ = "READ"
}

export interface NotificationDTO {
  id: number

  title: string
  message: string
  senderUserId: string
  receiverUserId: string
  status: NotificatonStatus;
}

export interface InsuranceDataDTO {
  id: number
  companyName: string
  insurancePremium: number
  insurancePolicyName: string
  expirationDate: Date;
  boatId: number
  boat?: BoatDTO
}

export enum CategoryType {
  TRANSITO = "TRANSITO",
  MENSILE = "MENSILE",
  PERIODICO = "PERIODICO"
}

export interface PriceCategoryDTO {
  id: number

  name: string;
  fromDate: Date;
  toDate: Date;
  type: CategoryType;

  berthPrices?: BerthPriceDTO[];
}

export interface BerthPriceDTO {
  id: number
  berthId: number
  price: number
  category?: PriceCategoryDTO
  berth?: BerthDTO
  date: string
}

export interface BerthPriceRuleDTO {
  id: number
  lengthFrom: number
  lengthTo: number
  price: number
  dateFrom: string
  dateTo: string
  currencyIso3: CurrencyIso3Type
  portId: number
  port: PortDTO
  enabled: Boolean
}

export enum CurrencyIso3Type {
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP",
  CNY = "CNY",
  INR = "INR",
}

export interface PortDTO {
  id: number;
  name: string;
  bgImageUrl: string;
  mapUrl: string;

  maxLenght: number;
  draft: number;
  vhfChannel: string;

  berths: BerthDTO[]
}


export interface ReservationDTO {
  id: number
  updatedAt: Date
  createdAt: Date

  fromDate: Date
  toDate: Date
  berthId: number
  berth?: BerthDTO
  boatId: number
  boat?: BoatDTO
}

export enum AttachmentType {
  BOAT_IMAGE = "BOAT_IMAGE",
  BOAT_DOC = "BOAT_DOC",
  GENERIC = "GENERIC",
  PORT_IMAGE = "PORT_IMAGE",
  RESERVATION_REQ_DOC = "RESERVATION_REQ_DOC"
}

export interface AttachmentDirectoryDTO {
  id: number
  name: string
  createdAt: Date
  parentId: number
  parent?: AttachmentDirectoryDTO
  files?: AttachmentDTO[]
  boatId?: number
}

export interface AttachmentDTO {
  id: number
  name: string
  description: string
  expiration: Date;
  type: AttachmentType;
  mimeType: string;
  contentLength: number;
  url: string;
  directoryId?: number;
  parent?: AttachmentDirectoryDTO
  updatedAt: Date
  createdAt: Date
}

export interface ShipYardDataDTO {
  id: number;
  boatId: number;
  name: string;
  model: string;
  designer: string;
  buildYear: string;
  registrationDate: string;
  boat?: BoatDTO;
}


export interface SkipperDTO {
  createdAt: Date
  email: string
  firstname: string
  id: number
  lastname: string
  mobilePhone: string
  updatedAt: Date
}


export interface GroupRepresentationDTO {
  id: string;
  name: string;
  parentId: string;
}

export interface UserRepresentationDTO {
  id: String
  username: String
  firstName: String
  lastName: String
  email: String
  emailVerified?: Boolean
  createdTimestamp: string
  enabled: Boolean
  realmRoles?: [String]
  attributes?: [MapEntry]
  groups?: [GroupRepresentationDTO]
}

export interface MapEntry {
  key: String;
  value: Object[];
}


export interface TemporaryTokenDTO {
  token: string;
}

